import { GatsbySeo } from 'gatsby-plugin-next-seo'
import ValeTroca from 'src/components/servicos/ServicesPage/ValeTroca'

function Page() {
  return (
    <>
      <GatsbySeo title="Vale troca | Decathlon" noindex nofollow />
      <ValeTroca />
    </>
  )
}

export default Page
